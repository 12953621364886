const iframe = document.createElement('iframe')
iframe.id = 'harvest-iframe'

const overlay = document.createElement('div')
overlay.className = 'harvest-overlay'
overlay.appendChild(iframe)
overlay.addEventListener('click', close)

document.addEventListener('keyup', ({ key }) => {
  if (key === 'Escape') close()
})

export function open(url) {
  iframe.src = url
  document.body.appendChild(overlay)
}
export function close() {
  overlay.parentNode?.removeChild(overlay)
}
export function adjustHeight(height) {
  iframe.style.height = `${height}px`
}
