import * as lightbox from './platform/lightbox'
import stylesheet from './platform.scss?inline' // eslint-disable-line import/no-unresolved

/* eslint-disable */
// ☃  fixes unicode issues
const scheme = import.meta.env.VITE_SCHEME || 'https'
const baseUrl = `${scheme}://${import.meta.env.VITE_HARVESTAPP_PLATFORM_HOST}`

let lastRunningTimerData
let xdm = document.getElementById('harvest-messaging')

// Append the worker iframe
if (!document.getElementById('harvest-worker')) {
  let worker = document.createElement('iframe')
  worker.hidden = true
  worker.id = 'harvest-worker'
  worker.src = `${baseUrl}/platform/worker`
  document.body.appendChild(worker)
}

// Append the custom messaging bus element
if (!xdm) {
  xdm = document.createElement('div')
  xdm.id = 'harvest-messaging'
  xdm.hidden = true
  document.body.appendChild(xdm)
}

let param = function (params) {
  let results = []

  for (let name in params) {
    let value = params[name]
    if (value != null) {
      results.push(`${name}=${encodeURIComponent(value)}`)
    }
  }
  return results.join('&')
}

let config = function () {
  if (window._harvestPlatformConfig) {
    return window._harvestPlatformConfig
  } else {
    return JSON.parse(
      document.querySelector('script[data-platform-config]').dataset
        .platformConfig
    )
  }
}

// Get data attributes from a given element
// el - HTMLElement containing data attributes as JSON strings
// Return an Object containing data attributes from the element
let getData = function (el) {
  let data = {}
  let attributes = ['account', 'item', 'group', 'default', 'skip-styling']

  for (let i = 0; i < attributes.length; i++) {
    let key = attributes[i]
    if (el.getAttribute(`data-${key}`)) {
      data[key] = getValue(el, key)
    } else {
      data[key] = null
    }
  }

  if (data.group == null) {
    data.group = getValue(el, 'project')
  }
  data.permalink = el.getAttribute('data-permalink')
  return data
}

let getValue = function (el, key) {
  let value

  // Attempt to parse the value as JSON
  try {
    value = JSON.parse(el.getAttribute(`data-${key}`))
  } catch (error) {}

  // If the value is not an object, set it as an object with an id property
  if (value?.id != null) {
    value.id = '' + value.id
  }
  return value
}

let setTimer = function (data) {
  lastRunningTimerData = data
  let lastRunningTimerGroup = data?.group?.id || undefined
  let lastRunningTimerItem = data?.item?.id || undefined
  let harvestTimers = document.querySelectorAll('.harvest-timer')
  let results = []

  //loop through all the timers and compare the timer data with the last running timer data
  for (let i = 0; i < harvestTimers.length; i++) {
    let timer = harvestTimers[i]
    let timerData = getData(timer)
    let group = timerData.group?.id || undefined
    let item = timerData.item?.id || undefined

    //if the last running timer data is null
    //or the timer group or item id is different from the last running timer group or item
    //remove the running class from the timer and it's children and return the results
    if (
      lastRunningTimerData == null ||
      group !== lastRunningTimerGroup ||
      item !== lastRunningTimerItem
    ) {
      timer.classList.remove('running')
      let removed = []
      for (let j = 0; j < timer.children.length; j++) {
        let child = timer.children[j]
        removed.push(child.classList.remove('running'))
      }
      results.push(removed)
    } else {
      //if the group and item id is the same as the last running timer group and item
      //add the running class to the timer and it's children and return the results
      timer.classList.add('running')
      let added = []
      for (let j = 0; j < timer.children.length; j++) {
        let child = timer.children[j]
        added.push(child.classList.add('running'))
      }
      results.push(added)
    }
  }
  return results
}

let stopTimer = function () {
  return setTimer(null)
}
// Construct a permalink

// template - String representing the template to be used for the permalink
// data - Object containing the data used for replacement of variables in the
//        template
let createPermalink = function (template, data) {
  if (template != null && data != null) {
    if (data.account != null) {
      template = template.replace('%ACCOUNT_ID%', data.account.id)
    }
    if (data.group != null) {
      template = template.replace('%PROJECT_ID%', data.group.id)
    }
    if (data.group != null) {
      template = template.replace('%GROUP_ID%', data.group.id)
    }
    if (data.item != null) {
      template = template.replace('%ITEM_ID%', data.item.id)
    }
  }
  return template
}

let listenForEvent = function (name, handler) {
  if (window.jQuery != null) {
    return window.jQuery(xdm).bind(name, handler)
  } else {
    return xdm.addEventListener(name, handler)
  }
}

// Handle messages from the worker iframe
// and the Platform timer page.
window.addEventListener('message', function (evt) {
  if (evt.origin !== baseUrl) {
    return
  }

  if (evt.data == null) {
    return
  }

  const { type, value } = evt.data
  switch (type) {
    case 'frame:close':
      return lightbox.close()
    case 'frame:resize':
      return lightbox.adjustHeight(value)
    case 'timer:started':
      const { id, group_id } = value.external_reference
      return setTimer({
        group: {
          id: group_id,
        },
        item: {
          id: id,
        },
      })
    case 'timer:stopped':
      return stopTimer()
  }
})

let HarvestPlatform = class HarvestPlatform {
  constructor({ stylesheet }) {
    let event, styleNode
    // Note: This event listener should be used when the harvest-timer elements
    // have already been added to the target page.
    this.addTimers = this.addTimers.bind(this)
    // Find all timer elements in the DOM
    this.findTimers = this.findTimers.bind(this)
    this.stylesheet = stylesheet
    styleNode = document.createElement('style')
    document.head.appendChild(styleNode)
    styleNode.appendChild(document.createTextNode(this.stylesheet))
    listenForEvent('harvest-event:timers:add', this.addTimers)
    listenForEvent('harvest-event:timers:chrome:add', this.findTimers)
    this.findTimers()
    xdm.setAttribute('data-ready', true)
    event = document.createEvent('CustomEvent')
    event.initCustomEvent('harvest-event:ready', true, true, {})
    ;(document.body || xdm).dispatchEvent(event)
  }

  addTimers(e) {
    let element, ref, ref1, ref2
    element =
      e.element ||
      ((ref = e.originalEvent) != null
        ? (ref1 = ref.detail) != null
          ? ref1.element
          : void 0
        : void 0) ||
      ((ref2 = e.detail) != null ? ref2.element : void 0) // dispatchEvent, handled with addEventListener
    if ((element != null ? element.jquery : void 0) != null) {
      element = element.get(0)
    }
    if (element) {
      return this.findTimer(element)
    }
  }

  findTimers() {
    let element, elements, i, len, results, selector
    selector = '.harvest-timer:not([data-listening])'
    elements = document.querySelectorAll(selector)
    results = []
    for (i = 0, len = elements.length; i < len; i++) {
      element = elements[i]
      results.push(this.findTimer(element))
    }
    return results
  }

  // Find the timer associated with the given element

  // element - HTMLElement representing a timer
  findTimer(element) {
    let skipAttr = element.getAttribute('data-skip-styling')

    let skipStyling =
      config().skipStyling ||
      element.classList.contains('styled') ||
      (skipAttr != null && skipAttr !== false && skipAttr !== 'false')

    if (!skipStyling) {
      element.classList.add('styled')
    }

    element.addEventListener('click', (e) => {
      e.stopPropagation()
      return this.openIframe(getData(element))
    })

    // Check if its’s running and add `.running` class
    setTimer(lastRunningTimerData)

    return element.setAttribute('data-listening', true)
  }

  // Open a timer dialog for the given timer and pass the given timer data

  // timer - HTMLElement representing the harvest-timer
  // data - Object containing the timer data
  openIframe(data) {
    let ref, ref1, ref2, ref3, ref4, ref5, ref6
    let getParams = {
      app_name: config().applicationName,
      service: data.service || window.location.hostname,
      permalink: data.permalink || createPermalink(config().permalink, data),
      external_account_id: (ref = data.account) != null ? ref.id : void 0,
      external_group_id: (ref1 = data.group) != null ? ref1.id : void 0,
      external_group_name: (ref2 = data.group) != null ? ref2.name : void 0,
      external_item_id: (ref3 = data.item) != null ? ref3.id : void 0,
      external_item_name: (ref4 = data.item) != null ? ref4.name : void 0,
      default_project_code:
        (ref5 = data.default) != null ? ref5.project_code : void 0,
      default_project_name:
        (ref6 = data.default) != null ? ref6.project_name : void 0,
    }
    return lightbox.open(`${baseUrl}/platform/timer?${param(getParams)}`)
  }
}
if (window.postMessage == null) {
  console.warn(`Harvest Platform is disabled.
To start and stop timers, cross-domain messaging must be supported
by your browser.`)
} else if (
  !window.XMLHttpRequest ||
  !('withCredentials' in new XMLHttpRequest())
) {
  console.warn(`Harvest Platform is disabled.
To check for running timers, xhr requests with credentials must be
supported by your browser.`)
} else if (self.HarvestPlatform != null) {
  self.HarvestPlatform.findTimers()
} else {
  self.HarvestPlatform = new HarvestPlatform({ stylesheet })
}
/* eslint-enable */
